export default [
    {
        quote: 'There should be no such thing as boring mathematics.',
        author: 'Edsger W. Dijkstra',
    },
    {
        quote: 'Mathematics is a language.',
        author: 'Josiah Willard Gibbs,',
    },
    {
        quote: 'Why do children dread mathematics? Because of the wrong approach. Because it is looked at as a subject.',
        author: 'Shakuntala Devi',
    },
    {
        quote: 'Somehow it’s okay for people to chuckle about not being good at math. Yet, if I said “I never learned to read,” they’d say I was an illiterate dolt.',
        author: 'Neil deGrasse Tyson',
    },
];
