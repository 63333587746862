import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import createSagaMiddleware from 'redux-saga';

import analyticsReducer from './analytics/analytics.reducer';
import appReducer from './app/app.reducer';
import booksReducer from './books/books.reducer';

import rootSaga from './rootSaga';

const saga = createSagaMiddleware({
    context: {
        api: axios.create({
            baseURL: 'https://app.prepanywhere.com/api/stu',
            timeout: 5000,
        }),
    },
});

const store = configureStore({
    reducer: {
        analytics: analyticsReducer,
        app: appReducer,
        books: booksReducer,
    },
    middleware: () => [saga],
});

saga.run(rootSaga);

export default store;
