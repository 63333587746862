import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './index.css';

import { DownloadBookProvider } from './contexts/downloadBookContext';
import store from './redux/store';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <DownloadBookProvider>
            <App />
        </DownloadBookProvider>
    </Provider>
);
