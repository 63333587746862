import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    instance: undefined,
};

export const analyticsReducer = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        createInstance: (state) => state,
        createInstanceSuccess: (state, { payload }) => {
            state.instance = payload;
        },
        trackPageView: (state) => state,
        trackEvent: (state) => state,
    },
});

export const { createInstance, createInstanceSuccess, trackPageView, trackEvent } = analyticsReducer.actions;

export default analyticsReducer.reducer;
