export default [
    {
        question: 'Lecture videos',
        answers: ['All our questions have accompanying lectures'],
    },
    {
        question: 'Curriculum-matched Questions',
        answers: ['Each workbook has over 500 questions that are matched to the grade or subject level'],
    },
    {
        question: 'Solution videos',
        answers: ['Each question has a step-by-step solution video'],
    },
];
