import MainLoader from '../MainLoader/MainLoader';
import classes from './PageLoader.module.css';

const PageLoader = () => {
    return (
        <div className={classes.pageloader}>
            <MainLoader />
        </div>
    );
};

export default PageLoader;
