import { useState } from 'react';
import { urls } from '../../../libs/constants';

import Button from '../Button/Button';

const { PAPER_FORM_URL } = urls;

const BookConsultationButton = () => {
    const [isHovering, setHover] = useState(false);
    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Button type="external-link" url={PAPER_FORM_URL}>
                {isHovering ? "Let's get that A+!" : 'Book Consultation'}
            </Button>
        </div>
    );
};

export default BookConsultationButton;
