export const capitalize = (str) => {
    const arr = str.split(' ');

    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(' ');
};

export const titleCase = (str) => capitalize(str.replace(/-/g, ' '));

export const sanitizeBookName = titleCase;

export const sanitizeMaterialName = (str) => {
    const match = str.match(/(\d+-\d+)-/);
    if (match) {
        const materialName = str.replace(match[1], match[1].replace(/-/g, '.'));
        return titleCase(materialName);
    }

    return titleCase(str);
};
