import { createSlice } from '@reduxjs/toolkit';
import { mapHelpers } from '../../libs/helpers';

const { updateProperty } = mapHelpers;

const initialState = {
    books: {},
    questionIds: [],
    lectures: [],
    questions: {},
};

export const booksReducer = createSlice({
    name: 'books',
    initialState,
    reducers: {
        getBook: (state) => state,
        getBookSuccess: (state, { payload }) => {
            const { bookName, book } = payload;
            state.books[bookName] = book;
        },
        getBookChapters: (state) => state,
        getBookChaptersSuccess: (state, { payload }) => {
            const { bookName, chapters } = payload;
            const path = ['books', bookName, 'chapters'];
            updateProperty(state, path, chapters);
        },
        getBookMaterials: (state) => state,
        getBookMaterialsSuccess: (state, { payload }) => {
            const { questionIds } = payload;
            state.questionIds = questionIds;
        },
        getBookLectures: (state) => state,
        getBookLecturesSuccess: (state, { payload }) => {
            const { lectures } = payload;
            state.lectures = lectures;
        },
        getBookQuestion: (state) => state,
        getBookQuestionSuccess: (state, { payload }) => {
            const { questionId, question } = payload;
            state.questions[questionId] = question;
        },
    },
});

export const {
    getBook,
    getBookSuccess,
    getBookChapters,
    getBookChaptersSuccess,
    getBookMaterials,
    getBookMaterialsSuccess,
    getBookLectures,
    getBookLecturesSuccess,
    getBookQuestion,
    getBookQuestionSuccess,
} = booksReducer.actions;

export default booksReducer.reducer;
