import { urls } from '../../constants';
import { getData } from '../request';

const { S3_DATA_URL } = urls;

export const getS3Data = async (fileName) => {
    try {
        const data = await getData(`${S3_DATA_URL}/${fileName}.json`);
        return data;
    } catch (_) {
        return {
            [fileName]: [],
        };
    }
};
