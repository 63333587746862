import { all, call, put, takeEvery } from 'redux-saga/effects';
import { api } from '../../libs/helpers';
import { trackEvent } from '../analytics/analytics.reducer';
import { getData, getDataSuccess, setAppStore, submitForm } from './app.reducer';

const submitFormSaga = function* ({ payload }) {
    const { url, text, type, bookName } = payload;

    try {
        yield call(api.postSlackForm, url, JSON.stringify({ text }));
    } catch (_) {
        // Something went wrong with slack
    }

    try {
        if (type === 'book') {
            yield call(api.postBookPdf, payload);
            yield put(
                trackEvent({
                    category: 'User',
                    action: `downloaded_${bookName}`,
                    label: `download_${bookName}`,
                })
            );
        }
    } catch (e) {
        // Something went wrong with server
    }

    yield setAppStore({ isSubmitting: false });
};

const getDataSaga = function* () {
    const dataKeys = ['faqs', 'quotes', 'books', 'blogs'];
    const results = yield all(dataKeys.map((key) => call(api.getS3Data, key)));
    const dataMap = results.reduce((map, group) => {
        map[group.fileName] = group.data;
        return map;
    }, {});
    yield put(getDataSuccess(dataMap));
};

const appSaga = function* () {
    yield takeEvery(setAppStore.type, function* () {});
    yield takeEvery(submitForm.type, submitFormSaga);
    yield takeEvery(getData.type, getDataSaga);
};

export default appSaga;
