import ReactGA from 'react-ga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { createInstance, createInstanceSuccess, trackEvent, trackPageView } from './analytics.reducer';

const analyticsStore = (state) => state.analytics;

const createInstanceSaga = function* () {
    yield ReactGA.initialize('UA-255807593');
    yield ReactGA.pageview(window.location.pathname + window.location.search);
    yield put(createInstanceSuccess(ReactGA));
};

const trackPageViewSaga = function* (payload) {
    const analytics = yield select(analyticsStore);
    yield analytics.instance.pageview(payload);
};

const trackEventSaga = function* (payload) {
    const analytics = yield select(analyticsStore);
    yield analytics.instance.event(payload);
};

const analyticsSaga = function* () {
    yield takeEvery(createInstance.type, createInstanceSaga);
    yield takeEvery(trackPageView.type, trackPageViewSaga);
    yield takeEvery(trackEvent.type, trackEventSaga);
};

export default analyticsSaga;
