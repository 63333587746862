import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import PageLoader from '../../components/loaders/PageLoader/PageLoader';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const Layout = () => {
    return (
        <>
            <Header />
            <main className="main">
                <Suspense fallback={<PageLoader />}>
                    <Outlet />
                </Suspense>
            </main>
            <Footer />
        </>
    );
};

export default Layout;
