import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    isSubmitting: false,
    hasError: false,
    errors: [],
    latestData: {},
};

export const appReducer = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppStore: (state, { payload }) => {
            if (payload) {
                Object.keys(payload).forEach((key) => {
                    state[key] = payload[key];
                });
            }
        },
        submitForm: (state) => {
            state.isSubmitting = true;
        },
        getData: (state) => state,
        getDataSuccess: (state, { payload }) => {
            state.latestData = payload;
        },
    },
});

export const { getData, getDataSuccess, setAppStore, submitForm } = appReducer.actions;

export default appReducer.reducer;
