import { fork } from 'redux-saga/effects';

import analyticsSaga from './analytics/analytics.saga';
import appSaga from './app/app.saga';
import booksSaga from './books/books.saga';

const rootSaga = function* () {
    yield fork(appSaga);
    yield fork(analyticsSaga);
    yield fork(booksSaga);
};

export default rootSaga;
