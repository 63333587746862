export const latexParser = (data) => {
    const parser = new DOMParser();

    const doc = parser.parseFromString(data?.question_html, 'text/html');
    const imageElement = doc.querySelector('img');
    const imageSrc = imageElement ? imageElement.getAttribute('src') : '';
    const imageId = imageSrc.substring(imageSrc.lastIndexOf('/') + 1);
    const isImageIdExist = data?.question?.slice(-(imageId.length + 1)) === `#${imageId}`;
    const questionData = isImageIdExist ? data.question?.slice(0, -(imageId.length + 1)) : data.question;

    return {
        ...data,
        imageId,
        question: questionData.replace(/`/g, ''),
    };
};
