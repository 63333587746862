import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as Facebook } from './../../../assets/icons/faceboook.svg';
import { ReactComponent as Instagram } from './../../../assets/icons/instagram.svg';
import Logo from './../../../assets/icons/logo.png';
import classes from './Header.module.css';

import BookConsultationButton from '../../../components/buttons/BookConsultationButton/BookConsultationButton';

const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);

    const menuToggleHandler = () => {
        setOpenMenu((isOpen) => !isOpen);
    };

    return (
        <header className={`${classes.header} ${openMenu ? classes.headerWhite : ''}`}>
            <div className="container">
                <nav className={classes.nav}>
                    <button
                        className={`${classes.navToggler} ${openMenu ? classes.navTogglerOpen : ''}`}
                        onClick={menuToggleHandler}
                    >
                        <span className={classes.navTogglerTop}></span>
                        <span className={classes.navTogglerBottom}></span>
                    </button>

                    <div className={classes.navLogo}>
                        <Link to="/">
                            <img src={Logo} className={classes.navLogoImage} alt="PrepBox" />
                        </Link>
                    </div>

                    <div className={`${classes.navMenu} ${openMenu ? classes.navMenuOpen : ''}`}>
                        <ul className={classes.navList}>
                            <li className={classes.navListItem} onClick={() => setOpenMenu(false)}>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li className={classes.navListItem} onClick={() => setOpenMenu(false)}>
                                <NavLink to="tutoring">Tutoring</NavLink>
                            </li>
                            <li className={classes.navListItem} onClick={() => setOpenMenu(false)}>
                                <NavLink to="workbooks">Workbooks</NavLink>
                            </li>
                            <li className={classes.navListItem} onClick={() => setOpenMenu(false)}>
                                <NavLink to="about">About</NavLink>
                            </li>
                            <li className={classes.navListItem} onClick={() => setOpenMenu(false)}>
                                <NavLink to="blog">Blog</NavLink>
                            </li>

                            <li className={classes.navListSocialItem}>
                                <a
                                    rel="noreferrer"
                                    aria-label="PrepBox Instagram"
                                    href="https://www.instagram.com/prepboxmath/"
                                    target="_blank"
                                >
                                    <Instagram />
                                </a>

                                <a
                                    rel="noreferrer"
                                    aria-label="PrepBox Facebook"
                                    href="https://www.facebook.com/prepboxmath"
                                    target="_blank"
                                >
                                    <Facebook />
                                </a>
                            </li>

                            <li>
                                <BookConsultationButton />
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
