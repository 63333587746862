export default [
    {
        quote: 'You helped regain my confidence in math, school, and most importantly, in my own intelligence.',
        author: 'Antony, Grade 12',
    },
    {
        quote: 'PrepBox not only teaches you math, it teaches you how to learn',
        author: 'Kiran, Grade 12',
    },
];
