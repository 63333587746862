import { urls } from '../../constants';
import { getData, postData } from '../request';

const { API_STATIC_BOOKS_URL, API_BOOK_URL } = urls;

export const getBook = (bookName) => getData(`${API_STATIC_BOOKS_URL}/book_details?book_name=${bookName}`);

export const getBookChapters = (bookName) => getData(`${API_STATIC_BOOKS_URL}/all_chapters?book_name=${bookName}`);

export const getBookMaterials = (bookName, chapterName, materialName) => getData( `${API_STATIC_BOOKS_URL}/all_questions?book_name=${bookName}&chapter_name=${chapterName}&material_name=${materialName}`);

export const getBookLectures = (topicId) => getData(`${API_STATIC_BOOKS_URL}/lecture_details?id=${topicId}`);

export const getBookQuestion = (questionId) => getData(`${API_STATIC_BOOKS_URL}/question_details?id=${questionId}`);

export const getLecturesFromTopic = (topicId) => getData(`${API_STATIC_BOOKS_URL}/lecture_details?id=${topicId}`);

export const postBookPdf = ({ bookId, email, userType }) => postData(`${API_BOOK_URL}?book_id=${bookId}&email=${email}&user_type=${userType}`);
