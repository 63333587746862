import axios from 'axios';

export const getData = async (url, config = {}) => {
    const response = await axios.get(url, config);
    return response.data;
};

export const postData = async (url, data = {}, config = {}) => {
    const response = await axios.post(url, data, config);
    return response.data;
};

export const postSlackData = async (url, data = {}) => {
    const dataStr = JSON.stringify(data);
    const response = await axios.post(url, dataStr, {
        withCredentials: false,
        transformRequest: [(data) => data],
    });

    return response.status;
};

export const updateData = async (url, data = {}, config = {}) => {
    const response = await axios.put(url, data, config);
    return response.data;
};

export const deleteData = async (url, data = {}, config = {}) => {
    const response = await axios.delete(url, data, config);
    return response.data;
};
