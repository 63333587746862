import { call, put, takeEvery } from 'redux-saga/effects';
import { api, latexHelpers } from '../../libs/helpers';
import { setAppStore } from '../app/app.reducer';
import {
    getBook,
    getBookChapters,
    getBookChaptersSuccess,
    getBookLectures,
    getBookLecturesSuccess,
    getBookMaterials,
    getBookMaterialsSuccess,
    getBookQuestion,
    getBookQuestionSuccess,
    getBookSuccess,
} from './books.reducer';

const { latexParser } = latexHelpers;

const catchError = function* (e) {
    let errorMsg = 'Something went wrong, Please try again!';

    switch (e) {
        case e.response:
            errorMsg = 'No Data Found!';
            break;
        case e.request:
            errorMsg = 'Network Error';
            break;
    }

    yield setAppStore({ isLoading: false, hasError: true, errors: [errorMsg] });
};

const getBookSaga = function* ({ payload }) {
    const { bookName } = payload;
    try {
        yield setAppStore({ isLoading: true, hasError: false, errors: [] });
        const book = yield call(api.getBook, bookName);
        yield put(getBookSuccess({ bookName, book }));
        yield setAppStore({ isLoading: false });
        yield getBookChaptersSaga({ payload });
    } catch (e) {
        yield catchError(e);
    }
};

const getBookChaptersSaga = function* ({ payload }) {
    const { bookName } = payload;
    try {
        yield setAppStore({ isLoading: true, hasError: false, errors: [] });
        const chapters = yield call(api.getBookChapters, bookName);
        yield put(getBookChaptersSuccess({ bookName, chapters }));
        yield setAppStore({ isLoading: false });
    } catch (e) {
        yield catchError(e);
    }
};

const getBookMaterialsSaga = function* ({ payload }) {
    const { bookName, chapterName, materialName } = payload;
    try {
        yield setAppStore({ isLoading: true, hasError: false, errors: [] });
        const questionIds = yield call(api.getBookMaterials, bookName, chapterName, materialName);
        yield put(getBookMaterialsSuccess({ questionIds }));
        yield setAppStore({ isLoading: false });
    } catch (e) {
        yield catchError(e);
    }
};

const getBookLecturesSaga = function* ({ payload }) {
    const { topicId } = payload;
    try {
        yield setAppStore({ isLoading: true, hasError: false, errors: [] });
        const lectures = yield call(api.getBookLectures, topicId);
        yield put(getBookLecturesSuccess({ topicId, lectures }));
        yield setAppStore({ isLoading: false });
    } catch (e) {
        yield catchError(e);
    }
};

const getBookQuestionSaga = function* ({ payload }) {
    const { questionId } = payload;
    try {
        yield setAppStore({ isLoading: true, hasError: false, errors: [] });
        const question = yield call(api.getBookQuestion, questionId);
        yield put(
            getBookQuestionSuccess({
                questionId,
                question: latexParser(question),
            })
        );
        yield setAppStore({ isLoading: false });
    } catch (e) {
        yield catchError(e);
    }
};

const booksSaga = function* () {
    yield takeEvery(getBook.type, getBookSaga);
    yield takeEvery(getBookChapters.type, getBookChaptersSaga);
    yield takeEvery(getBookMaterials.type, getBookMaterialsSaga);
    yield takeEvery(getBookLectures.type, getBookLecturesSaga);
    yield takeEvery(getBookQuestion.type, getBookQuestionSaga);
};

export default booksSaga;
